import { useCallback, useRef, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Navbar,
  Row,
} from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import axiosApi from "../../axios/axios-api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { jwtVerify } from "jose";
import ReCAPTCHA from "react-google-recaptcha";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import login_img from "../../assets/images/login-img.svg";
import error_img from "../../assets/images/error-img.svg";
import password_hide_icon from "../../assets/images/password-hide-icon.svg";
import password_show_icon from "../../assets/images/password-show-icon.svg";
import { MiniLoader } from "../ui/MiniLoader";
import "../../styles/styles.css";
import "./clientes.css";

const URL_GET_ALL_GASSTATIONS = "/gasolinerasWeb";
const URL_CUSTOMER_SIGNUP = "/customers-signup";
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;

export const ValidateScreen = () => {
  const [validated, setValidated] = useState(false);
  const [gasstations, setGasstations] = useState([]);
  const [tk, setTk] = useState("");
  const [tkValid, setTkValid] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [intents, setIntents] = useState(0);
  const [email, setEmail] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [iconShowPassword, setIconShowPassword] = useState(password_hide_icon);
  const [passwordRepeatShow, setPasswordRepeatShow] = useState(false);
  const [iconShowPasswordRepeat, setIconShowPasswordRepeat] =
    useState(password_hide_icon);
  const [captchaValid, setCaptchaValid] = useState(null);
  const [source, setSource] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const captchaRef = useRef(null);

  const onChange = () => {
    if (captchaRef.current.getValue()) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const handleShowPassword = () => {
    setPasswordShow(!passwordShow);
    passwordShow
      ? setIconShowPassword(password_hide_icon)
      : setIconShowPassword(password_show_icon);
  };

  const handleShowPasswordRepeat = () => {
    setPasswordRepeatShow(!passwordRepeatShow);
    passwordRepeatShow
      ? setIconShowPasswordRepeat(password_hide_icon)
      : setIconShowPasswordRepeat(password_show_icon);
  };

  const [errorMsg, setErrorMsg] = useState(t("validate.alert.error.post"));

  const fetchGasstationsData = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API) {
        const con = axios.create({
          baseURL: process.env.REACT_APP_URL_API,
          headers: {
            "Content-type": "application/json",
          },
        });
        const response = await con.get(URL_GET_ALL_GASSTATIONS, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setGasstations(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchGasstationsData();
  }, [fetchGasstationsData]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tk = urlParams.get("tk");

    if (!tk) {
      navigate("/404");
    } else {
      const verifyToken = async () => {
        try {
          const keyBytes = new TextEncoder().encode(
            process.env.REACT_APP_SIGN_UP_JWT_SECRET_KEY
          );

          const { payload } = await jwtVerify(tk, keyBytes, {
            algorithms: ["HS256"],
          });
          setEmail(payload.email);
          setSource(payload.source);
          setTk(tk);
          setTkValid(true);
        } catch (error) {
          console.error(error);
          setTkValid(false);
        }
      };

      verifyToken();
    }
  }, []);

  const validatedEmail = (email) => {
    const re = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    // Valida que la contraseña tenga entre 8 y 15 caracteres, una letra mayúscula, una letra minúscula y un número
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}$/;
    return re.test(password);
  };

  const resetForm = () => {
    document.getElementById("email").value = "";
    document.getElementById("nombre").value = "";
    document.getElementById("apellidos").value = "";
    document.getElementById("password").value = "";
    document.getElementById("password-repeat").value = "";
    document.getElementById("gasolinera").value = "";
    document.getElementById("terminos").checked = false;
    captchaRef.current.reset();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(false);
    setLoading(true);
    setIntents(intents + 1);

    const email = document.getElementById("email");
    const nombre = document.getElementById("nombre");
    const apellidos = document.getElementById("apellidos");
    const password = document.getElementById("password");
    const passwordRepeat = document.getElementById("password-repeat");
    const gasolinera = document.getElementById("gasolinera");
    const terminos = document.getElementById("terminos");

    let dataValid = {
      email: false,
      nombre: false,
      apellidos: false,
      password: false,
      passwordRepeat: false,
      gasolinera: false,
      terminos: false,
    };

    if (!validatedEmail(email.value)) {
      email.setCustomValidity(" ");
      dataValid.email = false;
    } else {
      email.setCustomValidity("");
      dataValid.email = true;
    }

    if (nombre.value === "") {
      nombre.setCustomValidity(" ");
      dataValid.nombre = false;
    } else {
      nombre.setCustomValidity("");
      dataValid.nombre = true;
    }

    if (apellidos.value === "") {
      apellidos.setCustomValidity(" ");
      dataValid.apellidos = false;
    } else {
      apellidos.setCustomValidity("");
      dataValid.apellidos = true;
    }

    if (!validatePassword(password.value)) {
      password.setCustomValidity(" ");
      dataValid.password = false;
    } else {
      password.setCustomValidity("");
      dataValid.password = true;
    }

    if (passwordRepeat.value !== password.value) {
      passwordRepeat.setCustomValidity(" ");
      dataValid.passwordRepeat = false;
    } else {
      passwordRepeat.setCustomValidity("");
      dataValid.passwordRepeat = true;
    }

    if (gasolinera.value === "") {
      gasolinera.setCustomValidity(" ");
      dataValid.gasolinera = false;
    } else {
      gasolinera.setCustomValidity("");
      dataValid.gasolinera = true;
    }

    if (!terminos.checked) {
      terminos.setCustomValidity(" ");
      dataValid.terminos = false;
    } else {
      terminos.setCustomValidity("");
      dataValid.terminos = true;
    }

    setValidated(true);

    if (
      !dataValid.email ||
      !dataValid.nombre ||
      !dataValid.apellidos ||
      !dataValid.password ||
      !dataValid.passwordRepeat ||
      !dataValid.gasolinera ||
      !dataValid.terminos
    ) {
      setLoading(false);
      return;
    } else {
      if (!captchaValid) {
        setCaptchaValid(false);
        setLoading(false);
        return;
      }

      let data = {
        token: tk,
        firstName: nombre.value,
        lastName: apellidos.value,
        password: password.value,
        match_password: passwordRepeat.value,
        idgas: gasolinera.value,
        captcha: captchaRef.current.getValue(),
      };
      try {
        if (process.env.REACT_APP_URL_CONFIRMATION_SERVICE) {
          const headers = {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_CONFIRMATION_SERVICE_API_KEY,
          };
          const con = axios.create({
            baseURL: process.env.REACT_APP_URL_CONFIRMATION_SERVICE,
            headers,
          });
          const response = await con.post(URL_CUSTOMER_SIGNUP, data, headers);
          if (response?.data?.statusCode === 200) {
            setErrorForm(false);
            setLoading(false);
            resetForm();
            setValidated(false);
            navigate("/clientes/resume", {
              state: { status: "ok", source: source },
            });
          } else {
            setErrorForm(true);
            setErrorMsg(response.data.statusDescription);
            setLoading(false);
            setValidated(false);
            console.warn(response.data.statusDescription);
            captchaRef.current.reset();
            setCaptchaValid(null);
          }
        }
      } catch (error) {
        console.error(error);
        setErrorForm(t("validate.alert.error.post"));
        setErrorForm(true);
        setLoading(false);
        setValidated(false);
        captchaRef.current.reset();
        setCaptchaValid(null);
      }
    }
  };

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar collapseOnSelect expand="lg" className="menu px-1">
          <Navbar.Brand>
            <img
              src={petroprix_logo}
              className="d-inline-block align-top"
              alt={t("login.menu.text")}
            />
          </Navbar.Brand>
        </Navbar>
      </Container>
      <Container className="content-validate-page">
        <header className="d-none d-lg-block">
          <img
            src={petroprix_logo}
            className="d-inline-block align-left validate-page-logo"
            alt={t("login.menu.text")}
          />
        </header>
        <Container className="validate-container py-1">
          <Row className="content-section form-section">
            <Col xs={12} lg={{ span: 12, order: 1 }}>
              <h1>
                {t("validate.header.h1")}
                <span className="red-text">{t("validate.header.h1.red")}</span>
              </h1>
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 1, order: 3 }}
              className="d-none d-lg-block"
            >
              <Image
                src={tkValid ? login_img : error_img}
                fluid="true"
                className="mt-5"
              />
            </Col>
            <Col xs={12} lg={{ span: 5, offset: 1, order: 3 }}>
              {tkValid ? (
                <>
                  <h2 className="mt-1">{t("validate.h2")}</h2>
                  {!errorForm && intents === 0 && (
                    <Alert variant="primary-login">
                      {t("validate.alert-info")}
                    </Alert>
                  )}

                  {captchaValid === false ? (
                    <Alert variant="danger-login">
                      <b>{t("error.form.captcha.not-valid")}</b>
                    </Alert>
                  ) : (
                    errorForm &&
                    intents > 0 && <Alert variant="danger">{errorMsg}</Alert>
                  )}
                  <Form
                    noValidate
                    validated={validated}
                    className="login-form"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <FloatingLabel
                      label={t("validate.form.email")}
                      className="mt-3"
                    >
                      <Form.Control
                        type="text"
                        id="email"
                        placeholder={t("validate.form.email")}
                        defaultValue={email}
                        readOnly
                        disabled
                      />
                      <Feedback type="invalid">
                        {t("validate.form.email.invalid")}
                      </Feedback>
                    </FloatingLabel>

                    <Row>
                      <Col xs={5} className="mt-1">
                        <FloatingLabel
                          label={t("validate.form.nombre")}
                          className="mt-3"
                        >
                          <Form.Control
                            type="text"
                            id="nombre"
                            placeholder={t("validate.form.nombre")}
                            required
                          />
                          <Feedback type="invalid">
                            {t("validate.form.nombre.invalid")}
                          </Feedback>
                        </FloatingLabel>
                      </Col>

                      <Col xs={7} className="mt-1">
                        <FloatingLabel
                          label={t("validate.form.apellidos")}
                          className="mt-3"
                        >
                          <Form.Control
                            type="text"
                            id="apellidos"
                            placeholder={t("validate.form.apellidos")}
                            required
                          />
                          <Feedback type="invalid">
                            {t("validate.form.apellidos.invalid")}
                          </Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>

                    <FloatingLabel
                      label={t("validate.form.password")}
                      className="mt-3"
                    >
                      <Form.Control
                        type={passwordShow ? "text" : "password"}
                        id="password"
                        placeholder={t("validate.form.password")}
                        required
                        minLength="8"
                        maxLength="15"
                      />
                      <span
                        toggle="#password-field"
                        className="toggle-password"
                        onClick={handleShowPassword}
                      >
                        <img src={iconShowPassword} className="passwordIcon" />
                      </span>
                      <Feedback type="invalid">
                        {t("validate.form.password.invalid")}
                      </Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      label={t("validate.form.password-repeat")}
                      className="mt-3"
                    >
                      <Form.Control
                        type={passwordRepeatShow ? "text" : "password"}
                        id="password-repeat"
                        minLength="8"
                        maxLength="15"
                        placeholder={t("validate.form.password-repeat")}
                        required
                      />
                      <span
                        toggle="#password-field"
                        className="toggle-password"
                        onClick={handleShowPasswordRepeat}
                      >
                        <img
                          src={iconShowPasswordRepeat}
                          className="passwordIcon"
                        />
                      </span>
                      <Feedback type="invalid">
                        {t("validate.form.password-repeat.invalid")}
                      </Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      label={t("validate.form.gasolinera")}
                      className="mt-3"
                    >
                      <Form.Select
                        aria-label="Gasolinera"
                        id="gasolinera"
                        placeholder={t("validate.form.gasolinera")}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          {t("validate.form.gasolinera.select")}
                        </option>
                        {gasstations.map((gasstation) => (
                          <option value={gasstation.id} key={gasstation.id}>
                            {gasstation.nombre_web !== null &&
                            gasstation.nombre_web !== ""
                              ? gasstation.nombre_web
                              : gasstation.nombre}
                          </option>
                        ))}
                      </Form.Select>
                      <Feedback type="invalid">
                        {t("validate.form.gasolinera.invalid")}
                      </Feedback>
                    </FloatingLabel>

                    {/* Terminos y condicones */}
                    <Form.Check
                      type="checkbox"
                      id="terminos"
                      label={
                        <>
                          {t("validate.form.terminos-1")}{" "}
                          <span className="red-text">
                            <a
                              href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                              target="_blank"
                            >
                              {t("validate.form.terminos-2")}
                            </a>
                          </span>
                        </>
                      }
                      className="mt-3"
                      required
                    />

                    <div className="mt-4 d-flex recaptcha">
                      <ReCAPTCHA
                        ref={captchaRef}
                        sitekey={SITE_KEY}
                        onChange={onChange}
                        required
                      />
                    </div>

                    <div className="d-grid gap-2 mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        size="lg"
                        className="form-validate-btn"
                        id="form-validate-btn"
                        disabled={loading}
                      >
                        {loading ? (
                          <MiniLoader />
                        ) : (
                          t("validate.form.btn-enviar")
                        )}
                      </Button>
                    </div>
                  </Form>
                </>
              ) : (
                <>
                  <h2>{t("validate.token.expired.title")}</h2>
                  <p>{t("validate.token.expired.text")}</p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
